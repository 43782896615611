import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification, Space, Spin } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { CategoryForm, CategoryTree } from '../../components/forms';

const Categories = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [edit, setEdit] = useState({ visible: false, category: {}, parent: {} });
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { categoryId } = props.match.params;

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (categoryId && categories.data.length > 0) {
      const selectedCategory = categories.data.find((c) => c._id === categoryId);
      setEdit({
        visible: true,
        category: selectedCategory || {},
        parent: selectedCategory ? selectedCategory.parent : {},
      });
    }
  }, [fetchCategories, categoryId, categories.data]);

  useEffect(() => {
    let data = [];
    if (currentuser.language) {
      data = getTreeFromFlatData({
        flatData: categories.data.map((node) => ({ ...node, title: node.naziv })),
        getKey: (node) => node.ID, // resolve a node's key
        getParentKey: (node) => node.parent.length > 0 ? node.parent[0].ID : null, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      });
    }
    data =
      data.length > 0
        ? data.map((data) => ({
          ...data,
          expanded:
            data.children && data.children.length > 0 && data.children.some((child) => child._id === categoryId),
        }))
        : [];
    setTreeData(data);
  }, [categories, currentuser, categoryId]);

  const deleteHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/categories/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Kategorija je izbrisana',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const loadingIcon = (<LoadingOutlined
    style={{ fontSize: 76, color: '#008139' }}
    spin
  />);

  const Spinner = (() =>
    <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', right: '0', bottom: '0', zIndex: '999', cursor: 'pointer' }}>
      <div style={{ display: 'grid', position: 'absolute', top: '50%', left: '50%', fontSize: '30px', color: 'white', transform: 'translate(-50%,-50%)', msTransform: 'translate(-50%,-50%)', }}>
        <Spin size='large' indicator={loadingIcon} />
        <div style={{ color: '#008139', marginTop: '20px' }}>Sinhronizacija...</div>
      </div>
    </div>
  );

  const editHandler = (id, parentNode) => {
    if (parentNode) {
      setEdit({ visible: true, category: { parent: parentNode }, parentNode });
    } else {
      setEdit({ visible: true, category: categories.data.find((c) => c._id === id), parentNode });
    }
  };

  // const removeImage = async (imageKey) => {
  //   try {
  //     await Axios.post(
  //       `${SERVER_URL}/remove-file/${imageKey}`,
  //       {},
  //       {
  //         withCredentials: false,
  //         headers: { Authorization: `Bearer ${currentuser.data.token}` },
  //       },
  //     );
  //   } catch (error) {
  //     console.log(error.response ? error.response.data.message : error.message);
  //   }
  // };

  const onSubmit = async (data, isNew) => {
    if (
      categories.data.find(
        (cat) => cat.name[currentuser.language.default.code] === data.name[currentuser.language.default.code],
      )
    ) {
      notification.error({
        message: `Kategorija sa istim imenom već postoji.`,
        placement: 'bottomRight',
      });
      return;
    }
    data.url = slugify(data.name[currentuser.language.default.code], { lower: true, strict: true });
    if (data.image === '') delete data.image;
    if (edit.category.parent) data.parent = edit.category.parent;

    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/categories` : `${SERVER_URL}/categories/${edit.category._id}`;

    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Kategorija je ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const syncCategories = async () => {
    try {
      setIsLoading(prev => !prev);
      await Axios.get(`${SERVER_URL}/sync-categories`);
      setIsLoading(prev => !prev);
      notification.success({
        message: `Uspešna sinhronizacija.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log('error', error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div>
      {isLoading && <Spinner />
      }

      <div className='edit-panel'>
        <div className='actions-block'>
          <Space>
            {/* <button className='b2b-primary' onClick={() => setEdit({ visible: true, category: {}, parentNode: {} })}>
            Dodaj kategoriju
          </button> */}
            <button className='b2b-primary' onClick={() => {
              syncCategories();
            }}>
              Sinhronizacija kategorija
            </button>
          </Space>
        </div>

        <Row type='flex' gutter={8}>
          <Col xs={24} md={edit.visible ? 16 : 24}>
            <div className='panel panel-primary' style={{ textAlign: 'center', padding: '8px' }}>
              {categories.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

              {!categories.isLoading && categories.data && categories.data.length > 0 && (
                <CategoryTree
                  treeData={treeData}
                  setTreeData={setTreeData}
                  token={currentuser.data.token}
                  setEdit={setEdit}
                  editHandler={editHandler}
                  deleteHandler={deleteHandler}
                />
              )}

              {!categories.isLoading && categories.data && categories.data.length === 0 && <h2>NEMA KATEGORIJA</h2>}
            </div>
          </Col>

          {edit.visible && currentuser.language && (
            <Col xs={24} md={8}>
              <CategoryForm
                onSubmit={onSubmit}
                category={edit.category}
                token={currentuser.data.token}
                language={currentuser.language}
                setEdit={setEdit}
                SERVER_URL={SERVER_URL}
                fetchCategories={fetchCategories}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Categories;
