import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select, Typography, Card } from 'antd';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState(data.order);
  const [total, setTotal] = useState(data.totalPrice ? data.totalPrice : 'Nema podataka');
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = ['On hold', 'Delivered', 'Canceled', 'Pending'];

  // useEffect(() => {
  //   setTotal(products.reduce((a, b) => b.discountPercentage ? a + b.quantity * (b.price - b.price * +b.discountPercentage / 100) : a + b.price * b.quantity, 0));
  // }, [products]);

  let filteredProducts = [];

  if (allProducts && products) {
    filteredProducts = allProducts.filter(item =>
      products.every(cartProduct => item.sifra !== cartProduct.code)
    );
  }

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const call = await axios.get(`${SERVER_URL}/data-admin`);
      setAllProducts(call.data.items);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = async () => {
    onSubmit({
      ...data,
      order: products,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * .2).toFixed(2),
      status: form.getFieldValue('status')
    });
    setTimeout(() => {
      window.location.reload();
    }, 700);
  };

  const updateOrderAndcreateInvoice = async () => {
    createInvoice({
      ...data,
      order: products,
      totalPrice: total.toFixed(2),
      totalPriceWithTax: (+total + +total * .2).toFixed(2),
      status: form.getFieldValue('status')
    });
    setTimeout(() => {
      window.location.reload();
    }, 700);
  };

  const discount = (percent, record) => {
    record.discountPercentage = percent;
    const newProducts = products.map(x => products.find(({ id }) => id === record._id) || x);
    setProducts(newProducts);
  };

  const increment = record => {
    record.quantity++;
    const newProducts = products?.map(x => products?.find(({ id }) => id === record?._id) || x);
    setProducts(newProducts);
  };

  const decrement = record => {
    if (record.quantity !== 1) {
      record.quantity--;
      const newProducts = products.map(x => products.find(({ id }) => id === record._id) || x);

      setProducts(newProducts);
    }
    return false;
  };

  const removeProduct = record => {
    const newProducts = products.filter(product => product._id !== record);
    setProducts(newProducts);
  };

  const addProduct = record => {
    const findProd = allProducts.find(p => p._id === record);
    const toAdd = {
      _id: findProd._id,
      category: findProd?.kategorija[0]?.naziv,
      code: findProd.sifra,
      name: findProd.naziv,
      quantity: 1,
      price: findProd?.cena[0]?.Cena || 0
    };
    const copy = [...products, toAdd];
    setProducts(copy);
  };

  const columnKeys = ['code', 'price'];
  let newColumnKeys = [];
  let name, price;
  for (const columnKey of columnKeys) {
    if (columnKey === 'code') {
      name = {
        originalName: columnKey,
        alternativeName: 'Sifra'
      };
    } else if (columnKey === 'price') {
      price = {
        originalName: columnKey,
        alternativeName: 'Cena'
      };
    }
  }
  newColumnKeys.push(name, price);
  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }

  let city = '';
  let address = '';
  let zip = '';
  let phone = '';
  if (data) {
    if (!data.company) {
      if (data.user.role[0] === 'companyAdmin') {
        city = data.user.companyAdminInfo.city;
        address = data.user.companyAdminInfo.address;
        zip = data.user.companyAdminInfo.zipCode;
        phone = data.user.phone[0];
      } else if (data.user.role[0] === 'company') {
        city = data?.user?.companies[0]?.mesto;
        address = data?.user?.companies[0]?.adresa;
        zip = data?.user?.companies[0]?.postanskibroj;
        phone = data?.user?.phone[0];
      }
    } else if (data.company) {
      city = data.company.mesto;
      address = data.company.adresa;
      zip = data.company.postanskibroj;
      phone = data?.user?.phone[0];
    }
  }
  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={() => onFinish()}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji narudžbe' bordered={false}>
                  <p>
                    <Text strong>Broj narudžbe: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {fullName !== '' ? fullName : `${data.guestUserData.firstName} ${data.guestUserData.lastName}`}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {city}
                  </p>
                  <p>
                    <Text strong>Adresa: </Text>
                    {address}
                  </p>
                  <p>
                    <Text strong>Telefon: </Text>
                    {phone}
                  </p>
                  <p>
                    <Text strong>Poštanski kod: </Text>
                    {zip}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {(+total).toFixed(2)}
                  </p>
                  {/* <p>
                    <Text strong>Ukupna cena sa porezom: </Text>
                    {(+total + +total * .2).toFixed(2)}
                  </p> */}
                </Card>
                <br />
                <Form.Item label='Status narudžbe' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {t(`${item}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <DataTable
                  status={data.status}
                  data={products}
                  columnKeys={newColumnKeys}
                  title={'OrderTable'}
                  increment={increment}
                  decrement={decrement}
                  removeProduct={removeProduct}
                  discount={discount}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.2rem' }}>
                  <Select
                    disabled={data.status !== 'On hold'}
                    placeholder={t(`${'Search and add new products'}`)}
                    showSearch
                    style={{ width: '60%' }}
                    onSelect={e => addProduct(e)}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {filteredProducts?.map(product => <Option value={product._id} key={product._id}>{product.naziv}</Option>)}
                  </Select>
                </div>
              </div>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button className='b2b-primary'
                htmlType='submit' style={{ margin: '0.5rem' }}>
                Ažuriraj
              </Button>
              <Button className='b2b-primary'
                disabled={data.status !== 'On hold'}
                onClick={() => updateOrderAndcreateInvoice()}
                style={{ margin: '0.5rem' }}>
                Potvrdi Porudžbenicu
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
