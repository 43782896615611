import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import ProFormInvoiceTable from '../../components/tables/ProFormInvoiceTable';
import { SERVER_URL } from '../../config';


const ProFormInvoices = () => {

  const currentuser = useContext(UserContext);
  const [proFormInvoices, fetchProFormInvoices] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchProFormInvoices(`${SERVER_URL}/proFormInvoices`, []);
  }, [fetchProFormInvoices]);

  // const deleteOrderHandler = async (id) => {
  //   try {
  //     await Axios.delete(`${SERVER_URL}/orders/${id}`, {
  //       withCredentials: false,
  //       headers: { Authorization: `Bearer ${currentuser.data.token}` },
  //     });
  //     notification.success({
  //       message: 'Stavka je obrisana.',
  //       placement: 'bottomRight',
  //     });
  //     history.push('/admin');
  //     history.push('/admin/orders');
  //   } catch (err) {
  //     notification.error({
  //       message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
  //       placement: 'bottomRight',
  //     });
  //   }
  // };

  const columnKeys = ['orderNumber', 'buyer', 'email', 'price', 'createdAt', 'updatedAt', 'status'];

  let tableData = [];
  if (proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length > 0) {
    tableData = proFormInvoices.data.items.map((item) => {
      let datumLocale = item.Datum?.split('T')[0];
      datumLocale = datumLocale?.split('-')?.reverse()?.join('.');
      item.Datum = `${datumLocale}`;
      return item;
    });
  }

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {proFormInvoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!proFormInvoices.isLoading && proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length > 0 && (
          <ProFormInvoiceTable
            data={tableData}
            // deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='ProFormInvoice'
            viewPath='/admin/view-proform-invoice/'
          />
        )}
        {!proFormInvoices.isLoading && proFormInvoices.data && proFormInvoices.data.items && proFormInvoices.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default ProFormInvoices;
