import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { Button, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { CompanyBranch } from '../../components/forms';

export default function AddCompanyBranch(props) {
  const user = useContext(UserContext);
  const [company, setCompany] = useState([]);
  const selectedCompany = localStorage.getItem('selectedCompany');
  const [data, fetchData] = useAxios('', {}, user.data.token, 'get');
  const { id } = props.match.params;

  const getCompanies = async () => {
    try {
      const call = await Axios.get(`${SERVER_URL}/companies/${selectedCompany}`, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      setCompany(call.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(`${SERVER_URL}/branches/${id}`, []);
    }
    getCompanies();
  }, [fetchData, id]);

  const createBranch = async (values) => {
    try {
      const create = await Axios.post(`${SERVER_URL}/branches`, values, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      notification.success({
        message: create.data.message,
        placement: 'bottomRight'
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateBranch = async (values, id) => {
    try {
      const update = await Axios.put(`${SERVER_URL}/branches/${id}`, values, {
        widthCredetnails: false,
        headers: {
          Authorization: `Bearer ${user.data.token}`
        }
      });
      notification.success({
        message: update.data.message,
        placement: 'bottomRight'
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <button className='b2b-primary'>Sve kompanije</button>
        </Link>
      </div>
      <div>
        {company &&
          <CompanyBranch
            company={company}
            branch={data.data}
            createBranch={createBranch}
            updateBranch={updateBranch}
          />
        }
      </div>
    </div>

  );
}
