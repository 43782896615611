import React, { useEffect, useState } from 'react';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Input, Checkbox, Button, Form, Row, Col, TreeSelect } from 'antd';

const DataFrom = ({ isNew, data, categories, language, onSubmit, SERVER_URL, token, declarations }) => {
  const [form] = Form.useForm();
  let initialValues;
  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);


  if (!isNew) {
    initialValues = { ...data[0] };
    initialValues.nekoristise = data[0]?.cena.length > 0 ? false : true;
  }

  if (data && data[0]?.stanje && data[0]?.stanje.length > 0) {
    if (data[0]?.stanje[0]?.stanje > 0) {
      initialValues.inStock = true;
    }
  }

  const treeData = getTreeFromFlatData({
    flatData: categories.map((node) => ({
      ...node,
      title: node.naziv.sr,
      value: node.ID,
      // key: node.ID,
      parent: node.IDnadredjene ? node.IDnadredjene : null,
      _id: node.ID,
    })),
    getKey: (node) => node.ID, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Naziv'
                  rules={[{ required: true, message: 'Molim Vas unesite naziv proizvoda!' }]}
                  name={'naziv'}
                >
                  <Input onChange={(event) => form.setFieldsValue({ url: event.target.value.replace(/ /g, '-').toLowerCase() })} />
                </Form.Item>
                <Form.Item
                  label='Šifra'
                  name='sifra'
                  rules={[{ required: true, message: 'Molim Vas unesite šifra proizvoda!' }]}>
                  <Input />
                </Form.Item>
                <Row justify='start'>
                  <Form.Item
                    label="Cena artikla"
                    name={['cena', '0', 'Cena']}
                    required={true}>
                    <Input
                      style={{ width: '150px' }}
                      type='number' />
                  </Form.Item>
                </Row>
                <Row justify='start'>
                  <Form.Item
                    label="Lager"
                    name={['stanje', '0', 'stanje']}
                    required={true}>
                    <Input
                      style={{ width: '150px' }}
                      type='number' />
                  </Form.Item>
                </Row>
                <Row gutter={14}>
                  <Col xs={24} md={8}>
                    <Form.Item label='Neaktivan proizvod' name='nekoristise' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={5}>
                    <Form.Item label='Na zalihama' name='inStock' valuePropName='checked'>
                      <Checkbox disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Kategorija</h4>
                </div>
                <br />
                <Form.Item
                  label='Kategorija'
                  name='IDgrupe'
                  className='panel-body'
                  rules={[{ required: true, message: 'Molim Vas da odaberete kategoriju!' }]}
                >
                  <TreeSelect
                    getPopupContainer={trigger => trigger.parentNode}
                    dropdownAlign={{ offset: [0, -80] }}
                    style={{ width: '100%', height: 30 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    name='category'
                    treeData={treeData}
                    placeholder='Kategorija'
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className='text-right'>
            <Button className='b2b-primary' htmltype='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} artikal
            </Button>
          </div>
        </Form>
      </div>
    </div >
  );
};

export default DataFrom;
