import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table, Input, DatePicker, Radio, InputNumber } from 'antd';
import DataTable from '../tables/DataTable';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: 'Kreiran',
  order: [],
  requirements: '',
  preuzimaKorisnik: 'N',
  isporucujeIBS: 'D',
  montiraIBS: 'D',
};

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const radioOptions = [
  { label: 'Da', value: 'D' },
  { label: 'Ne', value: 'N' },
];

const ServiceForm = ({
  data,
  onSubmit,
  createInvoice,
  currentUser,
  nalogodavci,
  changeNalogodavac,
  selectedNalogodavac,
  korisnici,
  changeKorisnik,
  selectedKorisnik,
  changePreuzimaKorisnik,
  preuzimaKorisnik,
  changeIsporucujeIBS,
  isporucujeIBS,
  montiraIBS,
  changeMontiraIBS,
  services,
  changeServices,
  articles,
  changeArticles,
}) => {
  const [form] = Form.useForm();
  const [date1, setDate1] = useState(moment(data.date1) || moment());
  const [date2, setDate2] = useState(moment(data.date2) || moment());
  let initialValues = { ...formInit, ...data };

  useEffect(() => {
    form.resetFields();
    changeServices(data.services || []);
    changeArticles(data.articles || []);
  }, []);

  const [newService, setNewService] = useState({
    sifra: '',
    label: '',
    amount: 1,
  });

  const [newArticle, setNewArticle] = useState({
    sifra: '',
    label: '',
    amount: 1,
  });

  const changeDate = (value, field) => {
    if (field === 'date1') {
      setDate1(value);
    }
    if (field === 'date2') {
      setDate2(value);
    }
  };

  const orderStatus = ['Kreiran', 'Započet', 'Završen', 'Čeka delove'];
  data?.comment?.map((item) => item.date = new Date(item.date).toLocaleDateString('sr-Latn-RS', dateOptions));

  const onFinish = async (values) => {
    onSubmit({
      ...values,
      commercialist: `${currentUser.firstName} ${currentUser.lastName}`,
      date1,
      date2,
      services,
      articles,
    });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 700);
  };

  const columnKeys = [
    {
      key: 'sifra',
      title: 'Šifra',
      dataIndex: 'sifra',
      width: '19%',
    },
    {
      key: 'label',
      title: 'Opis',
      dataIndex: 'label',
      width: '62%',
    },
    {
      key: 'amount',
      title: 'Kolicina',
      dataIndex: 'amount',
      width: '19%',
    },
  ];

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={onFinish}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={24}>
              <div className='panel-body'>
                <Card title='Detalji servisnog naloga' bordered={false}>
                  <p>
                    <Text strong>Broj servisnog naloga: </Text>
                    {data.serviceNumber || 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Datum izdavanja: </Text>
                    <DatePicker onChange={(value) => changeDate(value, 'date1')} value={date1} />
                  </p>
                  <p>
                    <Text strong>Ugovoreni rok za izvršenje usluge: </Text>
                    <DatePicker onChange={(value) => changeDate(value, 'date2')} value={date2} />
                  </p>
                  <p>
                    <Text strong>Komercijalista: </Text>
                    {data.offerId?.AgentNaziv}
                  </p>
                  <p>
                    <Text strong>Broj ponude: </Text>

                    <Form.Item
                      name='offerNumber'
                    >
                      <Input />
                    </Form.Item>
                  </p>
                  <Form.Item
                    label='Zahtev'
                    name='requirements'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Veza I (Ugovor)'
                    name='veza1' //missing
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Veza II (Ostalo)'
                    name='veza2' //missing
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Izvršioci-serviseri'
                    name='izvrsioc' //missing
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Nalogodavac'
                    name='nalogodavac' //missing
                  >
                    <Select
                      allowClear={true}
                      placeholder='Izaberi nalogodavca'
                      onChange={(value) => changeNalogodavac(nalogodavci.find(x => x._id === value))}
                      value={selectedNalogodavac?._id}
                    >
                      {
                        nalogodavci.map((item) => (
                          <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Korisnik'
                    name='korisnik' //missing
                  >
                    <Select
                      allowClear={true}
                      placeholder='Izaberi korisnika'
                      onChange={(value) => changeKorisnik(korisnici.find(x => x._id === value))}
                    // value={selectedKorisnik?._id}
                    >
                      {
                        korisnici.map((item) => (
                          <Select.Option key={item._id} value={item._id}>{item.interninaziv}</Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  {selectedKorisnik &&
                    <div style={{ marginTop: '10px' }}>
                      <p>
                        <Text strong>Adresa kupca: </Text>
                        {selectedKorisnik?.adresa}
                      </p>
                      <p>
                        <Text strong>Grad: </Text>
                        {selectedKorisnik?.mesto}
                      </p>
                      <p>
                        <Text strong>Kontakt osoba: </Text>
                        {selectedKorisnik?.licezakontakt}
                      </p>
                      <p>
                        <Text strong>Telefon kontakt osobe: </Text>
                        {selectedKorisnik?.tel}
                      </p>
                    </div>
                  }
                  <Form.Item
                    label='Napomena'
                    name='napomena' //missing
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Preuzima korisnik'
                    name='preuzimaKorisnik'
                  >
                    <Radio.Group
                      options={radioOptions}
                      onChange={(v) => changePreuzimaKorisnik(v.target.value)}
                      value={preuzimaKorisnik}
                      optionType='button'
                      buttonStyle='solid'
                      defaultValue={preuzimaKorisnik}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Isporučuje IBS'
                    name='isporucujeIBS'
                  >
                    <Radio.Group
                      options={radioOptions}
                      onChange={(v) => changeIsporucujeIBS(v.target.value)}
                      value={isporucujeIBS}
                      optionType='button'
                      buttonStyle='solid'
                      defaultValue={isporucujeIBS}
                    />
                  </Form.Item>
                  {
                    isporucujeIBS === 'D' &&
                    <Form.Item
                      label='Vozilo isporuke'
                      name='voziloIsporuke' //missing
                    >
                      {/*<Select*/}
                      {/*  allowClear={true}*/}
                      {/*  placeholder='Izaberi Vozilo'*/}
                      {/*  onChange={(value) => changeKorisnik(korisnici.find(x => x._id === value))}*/}
                      {/*  value={selectedKorisnik?._id}*/}
                      {/*>*/}
                      {/*  {*/}
                      {/*    korisnici.map((item) => (*/}
                      {/*      <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>*/}
                      {/*    ))*/}
                      {/*  }*/}
                      {/*</Select>*/}
                      <Input />
                    </Form.Item>
                  }
                  <Form.Item
                    label='Montira IBS'
                    name='montiraIBS'
                  >
                    <Radio.Group
                      options={radioOptions}
                      onChange={(v) => changeMontiraIBS(v.target.value)}
                      value={montiraIBS}
                      optionType='button'
                      buttonStyle='solid'
                      defaultValue={montiraIBS}
                    />
                  </Form.Item>
                  {
                    montiraIBS === 'D' &&
                    <Form.Item
                      label='Vozilo servisera'
                      name='voziloServisera' //missing
                    >
                      {/*<Select*/}
                      {/*  allowClear={true}*/}
                      {/*  placeholder='Izaberi vozilo'*/}
                      {/*  onChange={(value) => changeKorisnik(korisnici.find(x => x._id === value))}*/}
                      {/*  value={selectedKorisnik?._id}*/}
                      {/*>*/}
                      {/*  {*/}
                      {/*    korisnici.map((item) => (*/}
                      {/*      <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>*/}
                      {/*    ))*/}
                      {/*  }*/}
                      {/*</Select>*/}
                      <Input />
                    </Form.Item>
                  }
                  {/*<div style={{ padding: '10px' }}>*/}
                  {/*  <Table*/}
                  {/*    bordered*/}
                  {/*    size='middle'*/}
                  {/*    dataSource={data.comment}*/}
                  {/*    columns={columnKeys}*/}
                  {/*    pagination={false}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div style={{ padding: '10px 0' }}>
                    <Text strong>Usluge</Text>
                    <Table
                      bordered
                      size='middle'
                      dataSource={services}
                      columns={columnKeys}
                      pagination={false}
                      footer={() => {
                        return (
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                            <Input
                              placeholder='Šifra'
                              value={newService.sifra || ''}
                              onChange={(e) => {
                                setNewService({ ...newService, sifra: e?.target?.value });
                              }}
                              style={{ width: '19%' }}
                            />
                            <Input
                              placeholder='Opis'
                              value={newService.label || ''}
                              onChange={(e) => {
                                setNewService({ ...newService, label: e?.target?.value });
                              }}
                              style={{ width: 'auto' }}
                            />
                            <InputNumber
                              placeholder='Amount'
                              value={newService.amount || ''}
                              onChange={(e) => {
                                setNewService({ ...newService, amount: e });
                              }}
                              style={{ width: '19%' }}
                            />
                            <Button
                              className='b2b-primary'
                              style={{ margin: '0.5rem' }}
                              onClick={() => {
                                changeServices([...services, newService]);
                                setNewService({
                                  sifra: "",
                                  amount: 1,
                                  label: "",
                                });
                              }}
                            >
                              Dodaj
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div style={{ padding: '10px 0' }}>
                    <Text strong>Proizvodi</Text>
                    <Table
                      bordered
                      size='middle'
                      dataSource={articles}
                      columns={columnKeys}
                      pagination={false}
                      footer={() => {
                        return (
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                            <Input
                              placeholder='Šifra'
                              value={newArticle.sifra || ''}
                              onChange={(e) => {
                                setNewArticle({ ...newArticle, sifra: e?.target?.value });
                              }}
                              style={{ width: '19%' }}
                            />
                            <Input
                              placeholder='Opis'
                              value={newArticle.label || ''}
                              onChange={(e) => {
                                setNewArticle({ ...newArticle, label: e?.target?.value });
                              }}
                              style={{ width: 'auto' }}
                            />
                            <InputNumber
                              placeholder='Amount'
                              name='amount'
                              value={newArticle.amount || ''}
                              onChange={(e) => {
                                setNewArticle({ ...newArticle, amount: e });
                              }}
                              style={{ width: '19%' }}
                            />
                            <Button
                              className='b2b-primary'
                              style={{ margin: '0.5rem' }}
                              onClick={() => {
                                changeArticles([...articles, newArticle]);
                                setNewArticle({
                                  sifra: "",
                                  amount: 1,
                                  label: "",
                                });
                              }}
                            >
                              Dodaj
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </div>

                </Card>
                <br />
              </div>
              <div>
                <div className='XD' style={{ width: '200px', marginLeft: '14px' }}>
                  <Form.Item label='Status' name='status'>
                    <Select>
                      {orderStatus.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

            </Col>
            {/* <Col xs={24} md={16}>
              <div>
                {products && products.length > 0 &&
                  <Table
                    dataSource={products}
                    columns={columnKeys}
                    pagination={false}
                    rowKey='_id'
                  />
                }
              </div>
            </Col> */}
            <Col style={{ marginLeft: 'auto' }}>
              <Button className='b2b-primary'
                type="primary" htmlType="submit" style={{ margin: '0.5rem' }}>
                Ažuriraj
              </Button>
              {/* <Button className='b2b-primary'
                disabled={data.status !== 'On hold'}
                onClick={() => updateOrderAndcreateInvoice()}
                style={{ margin: '0.5rem' }}>
                Potvrdi ponudu
              </Button> */}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ServiceForm;
