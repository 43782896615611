import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL, IBS_TEST_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';
import CompanyForm from '../../components/forms/CompanyForm';
const { XMLParser } = require('fast-xml-parser');
const _ = require('lodash');

const EditCompany = (props) => {
  const parser = new XMLParser();
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/companies/${id}`, {});
  }, [fetchData, id]);

  const onSubmit = async (formData, isNew) => {
    const params = new URLSearchParams();
    if (!isNew) {
      params.append('kljuc', formData.ID);
    }
    params.append('sifra', formData.sifra);
    params.append('naziv', formData.interninaziv);
    params.append('pib', formData.pib);
    params.append('adresa', formData.adresa);
    params.append('postbr', formData.postanskibroj);
    params.append('mesto', formData.mesto);
    params.append('tel', formData.tel);
    params.append('fax', formData.fax);
    params.append('mail', formData.email);
    params.append('tekuci', formData.tekuciracun);
    params.append('rabatk', formData.rabatkupcu);
    params.append('rabatd', formData.rabatdobavljacu);
    params.append('rokplacanjakupcu', formData.rokplacanjakupcu);
    params.append('pravnofizicko', formData.pravnofizicko);
    params.append('proizvodjac', formData.proizvodjac);
    params.append('matbroj', formData.matbroj);
    params.append('licezakontakt', formData.licezakontakt);
    try {
      if (!isNew) {
        await Axios.post(`${IBS_TEST_URL}/CalculusWebService/CalculusWebService.asmx/AzurirajKomitenta`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          }
        );
      }
      else if (isNew) {
        await Axios.post(`${IBS_TEST_URL}CalculusWebService/CalculusWebService.asmx/UbaciKomitenta`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          }
        );
      }
      const newCompany = await Axios.get(`${IBS_TEST_URL}/CalculusWebService/CalculusWebService.asmx/PodaciKomitenta?sifra=${formData.sifra}&naziv=&pib=&ID=&email=&tel=&pf=&uslov=`);
      if (newCompany.status === 200 && isNew) {
        const parsedCompany = parser.parse(newCompany.data);
        const company = _.get(parsedCompany, 'DataSet.diffgr:diffgram.NewDataSet.Table', '');
        await Axios.post(`${SERVER_URL}/companies`, company,
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      }
      else if (newCompany.status === 200 && !isNew) {
        const parsedCompany = parser.parse(newCompany.data);
        const company = _.get(parsedCompany, 'DataSet.diffgr:diffgram.NewDataSet.Table', '');
        await Axios.put(`${SERVER_URL}/companies/${id}`, company,
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      }
      notification.success({
        message: `Komitent je ${isNew ? 'kreiran.' : 'izmenjen.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <button className='b2b-primary'>Svi komitenti</button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && <CompanyForm isNew={true} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} />}
        {id && !data.isError && data.data && isDataFetched && (
          <CompanyForm isNew={false} data={data.data} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
