import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';

const CompanyTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 210, marginBottom: 8, display: 'block' }}
          />
          <button className='b2b-primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}>
            Traži
          </button>
          <button className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      key: 'adresa',
      title: 'Adresa',
      dataIndex: 'adresa',
      ...getColumnSearchProps('adresa'),
    },
    {
      key: 'mesto',
      title: 'Grad',
      dataIndex: 'mesto',
      ...getColumnSearchProps('mesto'),
    },
    {
      key: 'drzava',
      title: 'Država',
      dataIndex: 'drzava',
      ...getColumnSearchProps('drzava'),
    },
    {
      key: 'interninaziv',
      title: 'Naziv',
      dataIndex: 'interninaziv',
      ...getColumnSearchProps('interninaziv'),
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      key: 'matbroj',
      title: 'Matični broj',
      dataIndex: 'matbroj',
      ...getColumnSearchProps('matbroj'),
    },
    {
      key: 'pib',
      title: 'PIB',
      dataIndex: 'pib',
      ...getColumnSearchProps('pib'),
    },
    {
      key: 'tel',
      title: 'Telefon',
      dataIndex: 'tel',
      ...getColumnSearchProps('tel'),
    },
  ];

  columns.push({
    title: 'action',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={viewPath + record._id}>
            <EyeOutlined title={`View ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      {data &&
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 15,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      }
    </div>

  );
};

export default CompanyTable;
