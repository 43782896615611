import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Input, Form, Card } from 'antd';
const { XMLParser } = require('fast-xml-parser');
const _ = require('lodash');


const ViewCompany = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;
  const [form] = Form.useForm();


  useEffect(() => {
    if (id) {
      fetchData(`${SERVER_URL}/companies/${id}`, {});
    }
  }, [fetchData, id, data]);
  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <button className='b2b-primary'>Svi komitenti</button>
        </Link>
      </div>

      {id && data && !data.isLoading && !data.isError && (
        <div className='dashboard'>
          <div className='card-wrapper'>
            <Card title='Pregled komitenta' bordered={false}>
              <Form className='form-horizontal' initialValues={{ ...data }} layout='vertical' form={form}>
                <Form.Item label='Naziv komitenta' name={['data', 'interninaziv']}>
                  <Input className='disabled'
                    disabled />
                </Form.Item>

                <Form.Item label='Matični broj' name={['data', 'matbroj']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Telefon' name={['data', 'tel']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Email' name={['data', 'email']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Poštanski broj' name={['data', 'postanskibroj']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Zemlja' name={['data', 'drzava']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Grad' name={['data', 'mesto']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Adresa' name={['data', 'adresa']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='PIB' name={['data', 'pib']}>
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Eksterni ID' name={['data', 'ID']}
                >
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Šifra' name={['data', 'sifra']} >
                  <Input
                    disabled />
                </Form.Item>

                <Form.Item label='Tekući račun' name={['data', 'tekuciracun']} >
                  <Input
                    disabled />
                </Form.Item>
              </Form >
            </Card>
          </div >
        </div >
      )}
    </div>
  );
};

export default ViewCompany;
