import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Button, Image, Spin } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'featuredProduct',
  'inactiveProduct',
  'superPrice',
  'newProduct',
  'discountWholesale',
  'discountRetail',
  'supplier',
  'createdAt',
  'updatedAt',
  'retailPrice',
  'discountRetailPrice',
  'discountWholesalePrice',
  'artikalID',
  'barkod',
  'nacinzaokrtanspak',
  'zaokrtranspak',
  'zemljaporekla',
  'zemljauvoza',
  'nacinzaokrtranspak',
  'uvoznik',
  'kataloskibrojevi',
  'IDgrupe',
  // 'nazivgrupe',
  'IDOsnovneGrupe',
  'nazivosnovnegrupe',
  'imaraster',
  'naakciji',
  'imarecepturu',
  'pokontrolnombroju',
  'poserijskombroju',
  'kreirano',
  'nekoristise',
  'nazivukasi',
  'stanje',
  'sifra',
  'jdm',
  'naziv',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const loadingIcon = <LoadingOutlined style={{ fontSize: 76, color: '#008139' }} spin />;

  const Spinner = () => (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '9999',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'grid',
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: '30px',
          color: 'white',
          transform: 'translate(-50%,-50%)',
          msTransform: 'translate(-50%,-50%)',
        }}
      >
        <Spin size='large' indicator={loadingIcon} />
        <div style={{ color: '#008139', marginTop: '20px' }}>Sinhronizacija...</div>
      </div>
    </div>
  );

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    fetchData(`${SERVER_URL}/data-admin`, []);
  }, [fetchData, fetchCategories]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Proizvod je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('naziv')) {
      columnKeys.push('naziv');
    }
    if (!columnKeys.includes('sifra')) {
      columnKeys.push('sifra');
    }

    let name;
    let sifra;
    for (const columnKey of columnKeys) {
      if (columnKey === 'naziv') {
        name = {
          originalName: columnKey,
          alternativeName: 'Naziv',
        };
      }
      if (columnKey === 'sifra') {
        sifra = {
          originalName: columnKey,
          alternativeName: 'Šifra',
        };
      }
    }
    newColumnKeys.push(sifra, name);
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.reserved = item.stanje.reduce((accumulator, currentValue) => accumulator + currentValue.reserved, 0);
      if (isNaN(item.reserved)) {
        item.reserved = 0;
      }
      item.loan = item.stanje.reduce((accumulator, currentValue) => accumulator + currentValue.loan, 0);
      if (isNaN(item.loan)) {
        item.loan = 0;
      }
      item.state = item.stanje.reduce((accumulator, currentValue) => accumulator + currentValue.stanje, 0);
      return item;
    });
  }

  const syncData = async () => {
    try {
      setIsLoading((prev) => !prev);
      await Axios.get(`${SERVER_URL}/sync-data`);

      setIsLoading((prev) => !prev);
      notification.success({
        message: `Uspešna sinhronizacija.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log('error', error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const syncDataState = async () => {
    try {
      setIsLoading((prev) => !prev);
      await Axios.post(`${SERVER_URL}/sync-dataState`);
      setIsLoading((prev) => !prev);
      notification.success({
        message: `Uspešna sinhronizacija.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log('error', error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const syncDataPrice = async () => {
    try {
      setIsLoading((prev) => !prev);
      await Axios.post(`${SERVER_URL}/sync-dataPrice`);
      setIsLoading((prev) => !prev);
      notification.success({
        message: `Uspešna sinhronizacija.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log('error', error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <div className='table'>
        <div className='actions-block'>
          <button
            className='b2b-primary'
            disabled={!currentuser.data.role.includes('superAdmin')}
            style={{ marginLeft: '10px' }}
            icon={<UploadOutlined />}
            onClick={() => syncData()}
            type='button'
          >
            Sinhronizacija artikala
          </button>
          <button
            className='b2b-primary'
            disabled={!currentuser.data.role.includes('superAdmin')}
            style={{ marginLeft: '10px' }}
            icon={<UploadOutlined />}
            onClick={() => syncDataPrice()}
            type='button'
          >
            Sinhronizacija cena artikala
          </button>
          <button
            className='b2b-primary'
            disabled={!currentuser.data.role.includes('superAdmin')}
            style={{ marginLeft: '10px' }}
            icon={<UploadOutlined />}
            onClick={() => syncDataState()}
            type='button'
          >
            Sinhronizacija stanja artikala
          </button>
        </div>

        <div style={{ textAlign: 'center' }}>
          {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!data.isLoading &&
            data.data &&
            data.data.items &&
            data.data.items.length > 0 &&
            !categories.isLoading &&
            categories.data &&
            categories.data.length > 0 && (
              <Table
                data={tableData}
                categories={categories.data}
                deleteHandler={deleteDataHandler}
                columnKeys={newColumnKeys}
                title='Data'
                editPath='/admin/edit-data/'
                viewPath='/admin/view-data/'
              />
            )}
          {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
        </div>
      </div>
    </div>
  );
};

export default Data;
