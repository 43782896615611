import React from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Input, Button, Form, Card } from 'antd';

export default function CompanyBranch({ company, createBranch, updateBranch, branch }) {
  const [form] = Form.useForm();
  const history = useHistory();
  let isNew = true;

  // for editing the branch
  if (Object.keys(branch).length) {
    isNew = false;
    Object.keys(branch).forEach(value => form.setFieldsValue({ [value]: branch[value] }));
    form.setFieldsValue({ company: branch.company.interninaziv });
  }

  const onFinish = (values) => {
    if (isNew) {
      values.phone = [values.phone];
      values.company = company?._id;
      createBranch(values);
    } else {
      values.company = branch.company._id;
      updateBranch(values, branch._id);
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={`${isNew ? 'Dodaj' : 'Ažuriraj'} ogranak`} bordered={false}>
          <Form
            className='form-horizontal'
            onFinish={(values) => onFinish(values)}
            layout='vertical'
            form={form}
          >
            <Form.Item label='Naziv ogranka' name='branchName'>
              <Input />
            </Form.Item>

            {company?.interninaziv && history.location.pathname === '/admin/new-branch' ?
              <Form.Item label='Kompanija' name='company'>
                <Input defaultValue={company?.interninaziv} disabled />
                <Input style={{ display: 'none' }} />
              </Form.Item>
              :
              <Form.Item label='Kompanija' name='company'>
                <Input disabled />
              </Form.Item>
            }

            <Form.Item label='Adresa' name='address'>
              <Input />
            </Form.Item>

            <Form.Item label='Grad' name='city'>
              <Input />
            </Form.Item>

            <Form.Item label='Poštanski broj' name='zipCode'>
              <Input />
            </Form.Item>

            <Form.Item label='Država' name='country'>
              <Input />
            </Form.Item>

            <Form.Item label='Telefon' name='phone'>
              <Input />
            </Form.Item>
            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmltype='submit'>
                {`${isNew ? 'Kreiraj' : 'Ažuriraj'} ogranak`}
              </button>
            </div>
          </Form >
        </Card>
      </div >
    </div >
  );
}
