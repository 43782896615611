import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ServiceForm from '../../components/forms/ServiceForm';

const dummyNalogodavci = [
  {
    name: "Marko Markovic",
    _id: "Marko Markovic"
  },
  {
    name: "Zarko Zarkovic",
    _id: "Zarko Zarkovic"
  },
  {
    name: "Jarko Jarkovic",
    _id: "Jarko Jarkovic"
  },
];

const dummyKorisnici = [
  {
    name: "Misko Pisko",
    _id: "1",
    address: "Prozivka 1",
    city: "Subotica",
    contactName: "Milica",
    contactNumber: "061354789",
  },
  {
    name: "Tomi Dzeri",
    _id: "2",
    address: "Radijalac 1",
    city: "Beograd",
    contactName: "Mila",
    contactNumber: "234255566",
  },
  {
    name: "Zirko Hrastic",
    _id: "3",
    address: "Sandor 1",
    city: "Novi Sad",
    contactName: "Mirka",
    contactNumber: "2345237654",
  },
];

const EditService = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [service, fetchService] = useAxios('', {}, currentuser.data.token, 'get');
  const [nalogodavci, setNalogodavci] = useState(dummyNalogodavci);
  const [selectedNalogodavac, setSelectedNalogodavac] = useState({});
  const [korisnici, setKorisnici] = useState([]);
  const [selectedKorisnik, setSelectedKorisnik] = useState();
  const [preuzimaKorisnik, setPreuzimaKorisnik] = useState("N");
  const [isporucujeIBS, setIsporucujeIBS] = useState("D");
  const [montiraIBS, setMontiraIBS] = useState("D");
  const [services, setServices] = useState([]);
  const [articles, setArticles] = useState([]);
  const { serviceId } = props.match.params;

  const fetchCompanies = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/companies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setKorisnici(res.data.items);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (serviceId) {
      fetchService(`${SERVER_URL}/service/${serviceId}`, {});
    }
    fetchCompanies();
  }, [fetchService, serviceId]);

  const onSubmit = async (formData) => {
    let method;
    let route;
    let text;
    if (serviceId) {
      method = 'put';
      route = `${SERVER_URL}/service/${serviceId}`;
      text = 'izmenjen';
    } else {
      method = 'post';
      route = `${SERVER_URL}/service`;
      text = 'kreiran';
    }
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Servisni nalog je ${text}.`,
        placement: 'bottomRight',
      });
      // history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = ((currentuser.language && currentuser.data && service.data && service.data.serviceNumber) || !serviceId);

  const handleChangeNalogodavac = (nalogodavac) => {
    setSelectedNalogodavac(nalogodavac);
  };

  const handleChangeKorisnik = (korisnik) => {
    setSelectedKorisnik(korisnik);
  };

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/service'>
          <button className='b2b-primary'>Svi servisni nalozi</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {isDataFetched && (
          <ServiceForm
            data={service.data || {}}
            currentUser={currentuser.data}
            nalogodavci={nalogodavci}
            selectedNalogodavac={selectedNalogodavac}
            changeNalogodavac={handleChangeNalogodavac}
            korisnici={korisnici}
            selectedKorisnik={selectedKorisnik}
            changeKorisnik={handleChangeKorisnik}
            changePreuzimaKorisnik={(v) => setPreuzimaKorisnik(v)}
            preuzimaKorisnik={preuzimaKorisnik}
            changeIsporucujeIBS={(v) => setIsporucujeIBS(v)}
            isporucujeIBS={isporucujeIBS}
            changeMontiraIBS={(v) => setMontiraIBS(v)}
            montiraIBS={montiraIBS}
            services={services}
            changeServices={(v) => setServices(v)}
            articles={articles}
            changeArticles={(v) => setArticles(v)}
            onSubmit={onSubmit}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditService;
