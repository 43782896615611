import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Input, Checkbox, Button, Form, Row, Col, TreeSelect, Table } from 'antd';

const ViewData = (props) => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState({});
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [reload, triggerReload] = useState(false);
  const { dataId } = props.match.params;
  const [form] = Form.useForm();
  let initialValues;

  const fetchData = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/data/${dataId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setData(res.data.data[0]);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (dataId) fetchData();
  }, [fetchCategories, dataId, reload]);

  let treeData;
  const isDataFetched = data && data._id && !categories.isLoading && categories.data && currentuser.language;
  if (isDataFetched) {
    initialValues = JSON.parse(JSON.stringify(data));
    if (data.nekoristise.includes('N')) {
      initialValues.nekoristise = false;
    } else {
      initialValues.nekoristise = true;
    }
    if (data.stanje[0]?.stanje > 0) {
      initialValues.inStock = true;
    }
    if (categories.data && categories.data.length > 0) {
      treeData = getTreeFromFlatData({
        flatData: categories.data.map((node) => ({
          ...node,
          title: node.naziv,
          value: node.ID,
          // key: node.ID,
          parent: node.IDnadredjene ? node.IDnadredjene : null,
          _id: node.ID,
        })),
        getKey: (node) => node.ID, // resolve a node's key
        getParentKey: (node) => node.parent, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      });
    }
  }

  const changeView = async (event, record) => {
    try {
      const updateRes = await Axios.put(`${SERVER_URL}/data-view-in-doc/${record._id}`, {
        showInDoc: event.target.checked
      }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } catch (error) {
      console.log('error during changing view state', error);
    }
    triggerReload((prev) => !prev);
  };

  const columns = [
    {
      title: 'Sifra',
      dataIndex: ['productId', 'sifra'],
      key: 'productId.sifra',
    },
    {
      title: 'Naziv',
      dataIndex: ['productId', 'naziv'],
      key: 'productId.naziv',
    },
    {
      title: 'Kolicina',
      dataIndex: 'Kolicina',
      key: 'Kolicina',
    },
    {
      title: 'Prikaz na dokumentu',
      width: '10%',
      render: (record) => {
        return (
          <Checkbox checked={record.showInDoc} value={record.showInDoc} onChange={(event) => changeView(event, record)} />
        );
      }
    }
  ];

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data'>
          <button className='b2b-primary'>Svi artikli</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {dataId && data && isDataFetched && (
          <div className='panel panel-body'>
            <div className='panel-body'>
              <Form
                className='form-horizontal'
                initialValues={initialValues}
                layout='vertical'
                form={form}
              >
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <div className='panel-body'>
                      <Form.Item
                        label='Naziv'
                        name='naziv'
                      >
                        <Input
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label='Šifra'
                        name='sifra'>
                        <Input disabled />
                      </Form.Item>
                      <Row justify='start'>
                        <Form.Item
                          label="Cena artikla"
                          name={['cena', '0', 'Cena']}>
                          <Input
                            disabled />
                        </Form.Item>
                      </Row>
                      <Row justify='start'>
                        <Form.Item
                          label="Lager"
                          name={['stanje', '0', 'stanje']}>
                          <Input
                            disabled />
                        </Form.Item>
                      </Row>
                      <Row gutter={14}>
                        <Col xs={24} md={8}>
                          <Form.Item label='Neaktivan artikal' name='nekoristise' valuePropName='checked'>
                            <Checkbox disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                          <Form.Item label='Na zalihama' name='inStock' valuePropName='checked'>
                            <Checkbox disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className='panel panel-primary'>
                      <div className='panel-heading'>
                        <h4 className='panel-title'>Kategorija</h4>
                      </div>
                      <br />
                      <Form.Item
                        label='Kategorija'
                        name='IDgrupe'
                        className='panel-body'
                        rules={[{ required: true, message: 'Molim Vas da odaberete kategoriju!' }]}
                      >
                        <TreeSelect
                          disabled
                          getPopupContainer={trigger => trigger.parentNode}
                          dropdownAlign={{ offset: [0, -80] }}
                          style={{ width: '100%', height: 30 }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          name='category'
                          treeData={treeData}
                          placeholder='Kategorija'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
              {initialValues.dataParts && initialValues.dataParts.length > 0 &&

                <div className='table'>
                  <div style={{ textAlign: 'center' }}>
                    <p style={{ marginTop: '20px' }}>Komponente koje sačinjavaju artikal:</p>
                    <div style={{ marginBottom: '0px' }}>
                      <Table
                        dataSource={initialValues.dataParts}
                        rowKey='IDSastojka'
                        columns={columns}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div >
        )}
      </div>
    </div >
  );
};

export default ViewData;
