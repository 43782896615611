import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ServicesTable';
import { SERVER_URL } from '../../config';


const Services = () => {

  const currentuser = useContext(UserContext);
  const [services, fetchServices] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchServices(`${SERVER_URL}/service`, []);
  }, []);

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/new-service'>
          <button className='b2b-primary'>Dodaj novi servisni nalog</button>
        </Link>
      </div>
      <div style={{ textAlign: 'center' }}>
        {services.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!services.isLoading && services.data && services.data.items && services.data.items.length > 0 && (
          <Table
            data={services.data.items}
            title='Services'
            viewPath='/admin/view-service/'
          />
        )}
        {!services.isLoading && services.data && services.data.items && services.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Services;
