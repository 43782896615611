import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, notification, Spin } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';
import { SERVER_URL, IBS_TEST_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'deliveryAddress',
  'commercialist',
  'termsOfPayment',
  'contactPerson',
  'discount',
  'createdAt',
  'updatedAt',
  'address',
  'pfah',
  'pak',
  'fax',
  'zvanicninaziv',
  'postanskibroj',
  'pib',
  'matbroj',
  'licezakontakt',
  'tipcenovnika',
  'napomena',
  'proizvodjac',
  'city',
  'country',
  'company',
  'pravnofizicko',
  'companyName',
  'companyCode',
  'taxId',
  'zipCode',
  'companyNumber',
  'phone',
  'ID',
  'analID',
  'kreirano',
  'rokplacanjakupcu',
  'tekuciracun',
  'rabatkupcu',
  'rabatdobavljacu',
  'sifra',
  'email'
];

const Companies = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const loadingIcon = (<LoadingOutlined
    style={{ fontSize: 76, color: '#008139' }}
    spin
  />);

  const Spinner = (() =>
    <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', right: '0', bottom: '0', zIndex: '999', cursor: 'pointer' }}>
      <div style={{ display: 'grid', position: 'absolute', top: '50%', left: '50%', fontSize: '30px', color: 'white', transform: 'translate(-50%,-50%)', msTransform: 'translate(-50%,-50%)', }}>
        <Spin size='large' indicator={loadingIcon} />
        <div style={{ color: '#008139', marginTop: '20px' }}>Sinhronizacija...</div>
      </div>
    </div>
  );

  useEffect(() => {
    fetchData(`${SERVER_URL}/companies`, []);
  }, [fetchData]);

  const syncCompanies = async () => {
    try {
      setIsLoading(prev => !prev);
      await Axios.get(`${SERVER_URL}/sync-companies`);
      setIsLoading(prev => !prev);
      notification.success({
        message: `Uspešna sinhronizacija.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log('error', error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const deleteDataHandler = async (record) => {

    const params = new URLSearchParams();
    try {
      await Axios.post(`${IBS_TEST_URL}/CalculusWebService/CalculusWebService.asmx/IzbaciKomitenta`, params, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      await Axios.delete(`${SERVER_URL}/companies/${record._id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Komitent je izbrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('pib')) {
      columnKeys.push('pib');
    }
    if (!columnKeys.includes('matbroj')) {
      columnKeys.push('matbroj');
    }
    let address, city, country, companyName, companyCode, taxId, phone;
    for (const columnKey of columnKeys) {
      if (columnKey === 'adresa') {
        address = {
          originalName: columnKey,
          alternativeName: 'Adresa'
        };
      } else if (columnKey === 'mesto') {
        city = {
          originalName: columnKey,
          alternativeName: 'Grad'
        };
      } else if (columnKey === 'drzava') {
        country = {
          originalName: columnKey,
          alternativeName: 'Zemlja'
        };
      } else if (columnKey === 'interninaziv') {
        companyName = {
          originalName: columnKey,
          alternativeName: 'Naziv komitenta'
        };
      } else if (columnKey === 'matbroj') {
        companyCode = {
          originalName: columnKey,
          alternativeName: 'Matični broj'
        };
      } else if (columnKey === 'pib') {
        taxId = {
          originalName: columnKey,
          alternativeName: 'PIB'
        };
      } else if (columnKey === 'tel') {
        phone = {
          originalName: columnKey,
          alternativeName: 'Telefon'
        };
      }
    }
    newColumnKeys.push(address, city, country, companyName, companyCode, taxId, phone);
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      // item.createdAt = new Date(item.createdAt).toLocaleString();
      // item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div>
      {isLoading && <Spinner />}
      <div className='table' style={{ padding: '8px' }}>
        <div className='actions-block'>
          {/* <Link to='/admin/new-company'>
          <button className='b2b-primary'>Dodaj komitenta</button>
        </Link> */}
          <button className='b2b-primary' disabled={!currentuser.data.role.includes('superAdmin')} style={{ marginLeft: '10px' }} icon={<UploadOutlined />} onClick={() => syncCompanies()} type="button">Sinhronizacija komitenata</button>
        </div>
        <div style={{ textAlign: 'center' }}>
          {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
            <Table data={tableData} deleteHandler={deleteDataHandler} columnKeys={newColumnKeys} title='Company' editPath='/admin/edit-company/' viewPath='/admin/view-company/' />
          )}
          {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
        </div>
      </div>
    </div>
  );
};

export default Companies;