import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import ViewData from '../pages/data/ViewData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import Offers from '../pages/offers/Offers';
import EditOrder from '../pages/orders/EditOrders';
import EditOffers from '../pages/offers/EditOffers';
import EditInvoices from '../pages/invoices/EditInvoice';
import Companies from '../pages/company/Company';
import EditCompany from '../pages/company/EditCompany';
import ViewCompany from '../pages/company/ViewCompany';
import AddCompanyBranch from '../pages/company/AddCompanyBranch';
import Services from '../pages/service/Services';
import EditService from '../pages/service/EditService';
import ProFormInvoices from '../pages/proFormInvoices/ProFormInvoices';
import EditProFormInvoice from '../pages/proFormInvoices/EditProFormInvoice';

const routes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['superAdmin', 'user'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Izmena korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
      {
        label: 'Pregled korisnika',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['superAdmin', 'user'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Komitenti',
    children: [
      {
        label: 'Svi komitenti',
        path: '/admin/companies',
        component: Companies,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj komitenta',
        path: '/admin/new-company',
        component: EditCompany,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni kompaniju',
        path: '/admin/edit-company/:id',
        component: EditCompany,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Vidi kompaniju',
        path: '/admin/view-company/:id',
        component: ViewCompany,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Dodaj ogranak',
        path: '/admin/new-branch',
        component: AddCompanyBranch,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni ogranak',
        path: '/admin/edit-branch/:id',
        component: AddCompanyBranch,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Artikli',
    children: [
      {
        label: 'Svi artikli',
        path: '/admin/data',
        component: Data,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Novi artikal',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Edit Data',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'View Data',
        path: '/admin/view-data/:dataId',
        component: ViewData,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Add/Edit Category',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Ponude i profakture',
    children: [
      {
        label: 'Sve ponude',
        path: '/admin/offers',
        component: Offers,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni ponudu',
        path: '/admin/view-offer/:offerId',
        component: EditOffers,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Sve profakture',
        path: '/admin/proform-invoices',
        component: ProFormInvoices,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni profakturu',
        path: '/admin/view-proform-invoice/:proFormId',
        component: EditProFormInvoice,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Narudžbe',
    children: [
      {
        label: 'Sve narudžbe',
        path: '/admin/orders',
        component: Orders,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Računi',
    children: [
      {
        label: 'Svi računi',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Edit invoice',
        path: '/admin/view-invoice/:invoiceId',
        component: EditInvoices,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Servis',
    children: [
      {
        label: 'Svi servisni nalozi',
        path: '/admin/service',
        component: Services,
        allowed: ['superAdmin'],
        showInMenu: true,
      },
      {
        label: 'Pregled servisnog naloga',
        path: '/admin/view-service/:serviceId',
        component: EditService,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Kreiranje servisnog naloga',
        path: '/admin/new-service',
        component: EditService,
        allowed: ['superAdmin'],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
