import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import OfferForm from '../../components/forms/OfferForm';

const EditOffers = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [offer, fetchOffer] = useAxios('', {}, currentuser.data.token, 'get');
  const { offerId } = props.match.params;
  useEffect(() => {
    if (offerId) {
      fetchOffer(`${SERVER_URL}/offers/${offerId}`, {});
    }
  }, [fetchOffer, offerId]);

  const onSubmit = async (formData) => {
    const method = 'put';
    const route = `${SERVER_URL}/offers/${offerId}`;

    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Ponuda je izmenjena.`,
        placement: 'bottomRight',
      });

      // history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async (orderData) => {
    try {
    } catch (error) {
      console.log(error);
    }
  };
  const isDataFetched = !offer.isLoading && offer && offer.data && offer.data.length > 0 && currentuser.language;

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/offers'>
          <button className='b2b-primary'>Sve ponude</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {offerId && !offer.isError && offer.data.length && isDataFetched && (
          <OfferForm
            data={offer.data[0]}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
            createInvoice={createInvoice}
          />
        )}
      </div>
    </div>
  );
};

export default EditOffers;
