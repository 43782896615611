import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  HistoryOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { useTranslation } from 'react-i18next';

const PostsTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, revisionPath }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + t(`${dataIndex}`)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small' 
            style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  // const columns = columnKeys.map((item) => ({
  //   key: item,
  //   title: item,
  //   dataIndex:
  //     item === 'createdBy'
  //       ? 'user'
  //       : ['title', 'category'].includes(item)
  //         ? [item, user.language ? user.language.selected.code : '']
  //         : item,
  //   ...getColumnSearchProps(
  //     item === 'createdBy'
  //       ? 'user'
  //       : ['title', 'category'].includes(item)
  //         ? [item, user.language ? user.language.selected.code : '']
  //         : item,
  //   ),
  // }));

  const columns = [
    {
      key: 'orderNumber',
      title: 'Broj narudžbenice',
      dataIndex: 'orderNumber',
    },
    {
      key: 'documentCode',
      title: 'Šifra dokumenta',
      dataIndex: 'documentCode',
    },
    {
      key: 'buyer',
      title: 'Kupac',
      dataIndex: 'buyer',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
    },
    {
      key: 'totalPrice',
      title: 'Za uplatu',
      dataIndex: 'totalPrice',
    },
  ];

  // columns.push({
  //   title: 'action',
  //   render: (text, record) => (
  //     <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
  //       <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //         <Link to={editPath + record._id}>
  //           <EditOutlined
  //             className='icon-unlock'
  //             // title={`Edit ${title.toLowerCase()}`}
  //             title={`Izmeni narudžbu`}
  //             style={{ textDecoration: 'none', color: 'black' }}
  //           />
  //           <EditFilled
  //             className='icon-lock'
  //             // title={`Edit ${title.toLowerCase()}`}
  //             title={`Izmeni narudžbu`}
  //             style={{ textDecoration: 'none', color: 'black' }}
  //           />
  //         </Link>
  //       </div>

  //       {viewPath && (
  //         <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //           <Link to={viewPath + record._id}>
  //             <EyeOutlined
  //               className='icon-unlock'
  //               title={`View ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //             <EyeFilled
  //               className='icon-lock'
  //               title={`View ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //           </Link>
  //         </div>
  //       )}

  //       <div style={{ margin: '2px', padding: '4px' }} className='lock'>
  //         <Popconfirm
  //           disabled={record._id === user.data.id}
  //           placement='left'
  //           // title={`This will delete ${title.toLowerCase()}`}
  //           title={`Ovo će izbrisati narudžbu`}
  //           onConfirm={() => {
  //             deleteHandler(record._id);
  //           }}
  //           okText='U redu'
  //           cancelText='Poništi'
  //           cancelButtonProps={{ className: 'b2b-secondary' }}
  //           okButtonProps={{ className: 'b2b-primary' }}
  //         >
  //           <DeleteOutlined className='icon-unlock'
  //             // title={`Delete ${title.toLowerCase()}`}
  //             title={`Obriši narudžbu`}
  //           />
  //           <DeleteFilled className='icon-lock'
  //             // title={`Delete ${title.toLowerCase()}`} 
  //             title={`Obriši narudžbu`}
  //           />
  //         </Popconfirm>
  //       </div>

  //       {revisionPath && (
  //         <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
  //           <Link to={revisionPath + record._id}>
  //             <HistoryOutlined
  //               className='icon-unlock'
  //               title={`Revision ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //             <HistoryOutlined
  //               className='icon-lock'
  //               title={`Revision ${title.toLowerCase()}`}
  //               style={{ textDecoration: 'none', color: 'black' }}
  //             />
  //           </Link>
  //         </div>
  //       )}
  //     </div>
  //   ),
  // });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default PostsTable;
