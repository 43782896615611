import React from 'react';
import { Divider, Input, Button, Form, Card } from 'antd';

const formInit = {
  companyName: undefined,
  address: undefined,
  city: undefined,
  zipCode: undefined,
  country: undefined,
  phone: undefined,
  taxId: undefined,
};

const CompanyForm = ({ isNew, data, onSubmit, language }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={isNew ? 'Dodaj komitenta' : 'Izmeni komitenta'} bordered={false}>
          <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
            <Form.Item label='Naziv komitenta' name='interninaziv'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete naziv komitenta!',
                },
              ]}>

              <Input placeholder='Naziv komitenta' />
            </Form.Item>

            <Form.Item label='Matični broj' name='matbroj'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete matični broj!',
                },
              ]}>

              <Input placeholder='Matični broj komitenta' />
            </Form.Item>


            <Form.Item label='Telefon' name='tel'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete broj telefona!',
                },
              ]}>
              <Input placeholder='Broj telefona' />
            </Form.Item>

            <Form.Item label='Email' name='email'>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item label='Poštanski broj' name='postanskibroj'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete poštanski broj!',
                },
              ]}>
              <Input placeholder='Poštanski broj komitenta' />
            </Form.Item>

            <Form.Item label='Zemlja' name='drzava'>
              <Input disabled={true} placeholder='Zemlja' />
            </Form.Item>

            <Form.Item label='Grad' name='mesto'>
              <Input disabled={true} placeholder='Grad' />
            </Form.Item>

            <Form.Item label='Adresa' name='adresa'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete adresu!',
                },
              ]}>
              <Input placeholder='Adresa komitenta' />
            </Form.Item>

            <Form.Item
              label='PIB'
              name='pib'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete PIB!',
                },
              ]}
            >
              <Input placeholder='Poreski broj komitenta' />
            </Form.Item>

            <Form.Item
              label='Eksterni ID'
              name='ID'
            >
              <Input placeholder='Eksterni ID'
                disabled={true} />
            </Form.Item>

            <Form.Item
              label='Šifra'
              name='sifra'
            >
              <Input placeholder='Šifra' />
            </Form.Item>

            <Form.Item
              label='Tekući račun'
              name='tekuciracun'
            >
              <Input placeholder='Tekući račun' />
            </Form.Item>

            <Form.Item label='Fax' name='fax'>
              <Input placeholder='Broj faxa' />
            </Form.Item>

            <Form.Item label='Rabat kupcu' name='rabatkupcu'>
              <Input placeholder='Rabat kupcu' />
            </Form.Item>

            <Form.Item label='Rabat dobavljaču' name='rabatdobavljacu'>
              <Input placeholder='Rabat dobavljaču' />
            </Form.Item>

            <Form.Item label='Rok plaćanja kupcu' name='rokplacanjakupcu'>
              <Input placeholder='Rok plaćanja kupcu' />
            </Form.Item>


            <Form.Item
              label='Kontakt osoba'
              name='licezakontakt'
            >
              <Input placeholder='Ime kontakt osobe' />
            </Form.Item>

            <Form.Item
              label='Pravno/fizicko'
              name='pravnofizicko'
            >
              <Input placeholder='Pravno/fizicko' />
            </Form.Item>

            <Form.Item
              label='Proizvodjac'
              name='proizvodjac'
            >
              <Input placeholder='Proizvodjac' />
            </Form.Item>

            {/* <Form.Item
              label='Diskont'
              name='discount'
            >
              <Input placeholder='Diskont' />
            </Form.Item> */}

            {/* <Form.Item
              label='Komercijalista'
              name='commercialist'
            >
              <Input placeholder='Ime komercijaliste' />
            </Form.Item> */}

            {/* <Form.Item
              label='Adresa za isporuku'
              name='deliveryAddress'
            >
              <Input placeholder='Adresa za isporuku' />
            </Form.Item> */}

            {/* <Form.Item
              label='Uslovi plaćanja'
              name='termsOfPayment'
            >
              <Input placeholder='Uslovi plaćanja' />
            </Form.Item> */}

            <Form.Item
              label='Napomena'
              name='napomena'
            >
              <Input placeholder='Napomena' />
            </Form.Item>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary' htmltype='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} komitenta
              </button>
            </div>
          </Form >
        </Card>
      </div >
    </div >
  );
};

export default CompanyForm;
