import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ProFormInvoiceForm from '../../components/forms/ProFormInvoiceForm';

const EditProFormInvoice = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [proFormInvoice, fetchProFormInvoice] = useAxios('', {}, currentuser.data.token, 'get');
  const { proFormId } = props.match.params;
  useEffect(() => {
    if (proFormId) {
      fetchProFormInvoice(`${SERVER_URL}/proFormInvoices/${proFormId}`, {});
    }
  }, [fetchProFormInvoice, proFormId]);
  const onSubmit = async (formData) => {
    const method = 'put';
    const route = `${SERVER_URL}/proFormInvoices/${proFormId}`;

    try {
      const res = await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Profaktura je izmenjena.`,
        placement: 'bottomRight',
      });

      history.push('/admin/proform-invoices');
      history.push(`/admin/view-proform-invoice/${res.data.id}`);

      // history.push('/admin/orders');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched =
    !proFormInvoice.isLoading &&
    proFormInvoice &&
    proFormInvoice.data &&
    proFormInvoice.data.length > 0 &&
    currentuser.language;

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/proform-invoices'>
          <button className='b2b-primary'>Sve profakture</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {proFormId && !proFormInvoice.isError && proFormInvoice.data.length && isDataFetched && (
          <ProFormInvoiceForm
            data={proFormInvoice.data[0]}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditProFormInvoice;
